.quick{

    position: relative;
    border-radius: 20px;
    overflow: hidden;
    padding: 50px;
    margin-top: 20px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        // opacity: .2;
    }

    .quick__main{

        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0px;

        .controls-x{

            position: absolute;
            right: 0;
            z-index: 4;
            gap: 10px;
            margin-top: 40px;
            width: 100%;    
            justify-content: space-between;
            bottom: 45px;
            z-index: 2;

            .arrow__nav{
    
                height: 50px;
                width: 50px;
                border-radius: 5px;
                position: relative;
                cursor: pointer;
                font-size: 18px;
                background-color: #fff;
    
                .abs{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin-top: 3px;
                    transition: all .2s ease-in-out;
                }
    
                &.abs1:hover{
    
                    .abs{
                        margin-left: -2px;
                    }
    
                }
    
                &.abs2:hover{
    
                    .abs{
                        margin-left: 2px;
                    }
    
                }
            }
        }

        .quick__content{
            position: relative;
            width: 700px;
            margin-left: 0px;
            color: #fff;

            p{
                font-size: 45px;
                font-weight: 600;
                margin-bottom: 20px;
                line-height: 51px;
                letter-spacing: -1px;
                text-shadow: 2px 0 30px rgba($color: #000000, $alpha: .5);

                span{
                    color: #61FF00
                }
            }

            .subtitle{

                font-weight: 13px;
                line-height: 24px;
                width: 75%;
                font-size: 15px;

            }
        }

        .search__cards__body{

            position: relative;
            margin-left: 0px;
            gap: 30px;
            margin: 0 70px;
            margin-top: 50px;
            overflow-x: auto;
            scrollbar-width: none;
            padding-right: 40px;
            scroll-behavior: smooth;
            z-index: 5;

            .search__card{

                width: 320px;
                min-width: 320px;
                height: 140px;
                background-color: #009C3E;
                border-radius: 10px;
                padding: 20px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 15px;
                cursor: pointer;
                color: #fff;

                &.active{
                    border: 3px solid #fff;
                }

                &:hover{
                    background-color: #fff;
                    color: #009C3E;
                }

                p{
                    font-size: 27px;
                    width: 90%;
                    font-weight: 600;
                    line-height: 32px;
                }

                .cta{
                    font-size: 14px;
                    font-weight: 600;
                    gap: 6px;

                    .arrt{

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 2px;

                    }
                }

            }
        }

    }

}


@media (max-width : 768px) {

    .quick{

        margin-top: 0px;

        .quick__main{

            height: 100%;
            padding: 50px 0;

            .controls-x{
                bottom: 95px;
            }

            img{
                left: 0;
            }

        }

    }
    
}

@media (max-width : 550px) {

    .quick{

        background-color: #f0f7f6;
        border-radius: 0;
        padding: 0;

        img{
            display: none;
        }

        padding: 0px;

        .quick__main{

            height: 100%;
            width: 100%;
            padding: 20px 0;
            // margin-top: 100px;

            .controls-x{
                display: none;
            }

            img{
                left: 0;
            }

            .quick__content {

                opacity: 1;
                width: 100%;
                margin-top: 0px;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 0 30px;

                p{
                    font-size: 32px;
                    text-align: center;
                    line-height: 37px;
                    text-shadow: none;
                    letter-spacing: -.5px;
                    color: #131313;
                    margin: 30px 0;
                    margin-bottom: 50px;
                }

                .subtitle{
                    width: 100%;
                    text-align: center;
                    margin-bottom: 30px;
                    font-size: 18px;
                    display: none;
                    color: #131313;
                }

            }

            .search__cards__body{
                flex-direction: column;
                gap: 20px;
                margin: 0;
                padding: 0;
                align-items: center;
                margin-bottom: 0px;

                .search__card{
                    width: calc(100% - 40px);
                    min-width: calc(100% - 40px);
                }
            }

        }

    }
    
}