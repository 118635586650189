.view_news{

    background-color: #fff;

    .overhold{
        justify-content: space-between;
        margin-top: 160px;
    }

    .news_container{

       margin-top: 0px;
       gap: 40px;
       justify-content: center;
       padding-bottom: 50px;
       margin-bottom: 50px;
       width: 62%;

       .back_to_news{

        margin-bottom: 30px;
        width: max-content;
        height: 50px;
        width: 50px;
        border-radius: 100px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        
        a{
            color: #000 !important;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 12px 15px;
            font-size: 11px;

            span{
                font-size: 11px;
                letter-spacing: 2px;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

    }

       .current_news{

        .dateNow{
            display: flex;
            gap: 20px 40px;
            align-items: center;
            font-weight: 600;
            font-size: 15px;
            margin-top: 20px;
            width: 100%;
            flex-wrap: wrap;

            .readtime{
                color: green;
            }

            .firstPart{
                gap: 10px;
            }

            .tagsArea{
                display: flex;
                gap: 10px;

                .categoryTag{

                    padding: 5px 10px;
                    background-color: #eee;
                    border-radius: 5px;
                    text-transform: uppercase;
                    font-size: 11px;
                    letter-spacing: 2px;
                    font-weight: 500;

                }
            }

            margin-bottom: 25px;
        }

        .current_news_title{

            margin-bottom: 20px;
            font-size: 35px;
            line-height: 47px;
            font-weight: 500;
            padding-bottom: 30px;
            padding-right: 100px;
            font-weight: 600;
            text-decoration: underline;

        }

        .current_news_image{

            width: 100%;
            height: 400px;
            border-radius: 10px;
            overflow: hidden;
            background-color: #f9f9f9;
            margin-bottom: 30px;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

        }

        .current_news_body{

            .ql-toolbar{
                display: none;
            }

            p, h1, h2, span{
                background-color: transparent !important;
                color: #000 !important;
                font-size: 16px !important;
                font-weight: normal !important;
                line-height: 28px;
                text-align: justify;
            }

        }

       }

    }

    .sub_news_container{

        background-color: #fff;
        width: calc(38% - 25px);
        height: max-content;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        border: 1px solid #eee;
        box-shadow: 0 4px 25px rgba($color: #000000, $alpha: 0.03);
        overflow-y: auto;

        .title__sub__news{
            margin-top: 10px;
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 10px;
           }

        a{
            color: #000;
        }

        .sub__news__data{

            display: flex;
            flex-direction: column;
            gap: 20px;


        }

        .subs_news{

            right: 0;
            top: 0;
            display: flex;
            gap: 20px;
            align-items: center;
            cursor: pointer;

            &:last-child{
                border: none;
            }

            &:hover{

                .sub__title{
                    text-decoration: underline;
                    color: #108a00;
                }
            }

            .sub__image{
                
                width: 100px;
                height: 80px;
                background-color: #eee;
                border-radius: 8px;
                overflow: hidden;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .sub__title{
                font-size: 13px;
                line-height: 20px;
                font-weight: 500;
                display: flex;
                gap: 10px;
                overflow: hidden;
                width: 60%;
                padding-right: 10px;
                flex-direction: column;
                font-weight: 600;

                .date{
                    font-size: 13px;
                    font-weight: 400;
                }
            }

        }

     }

}

@media (max-width : 1200px) {

    .view_news{

        .overhold{
            margin-top: 140px;
        }

        .news_container{
            .current_news{
                .current_news_title{
                    
                    padding-right: 0px;
                    padding-bottom: 20px;
                }
            }
        }
        
    }
    
}

@media (max-width : 970px) {

    .view_news{

        .sub_news_container{
            background-color: transparent;
            border: none;
            box-shadow: none;
            margin-bottom: 30px;
            width: 100%;
            padding: 0;
            border-top: 1px solid #eee;
            border-radius: 0;
            padding-top: 20px;

            .sub__news__data{

                display: flex;
                flex-direction: row;
                width: 100%;
                overflow-x: auto;
                scrollbar-width: none;
                
                .subs_news{
                    min-width: 280px;
                    flex-direction: column;
                    align-items: flex-start;
                    // height: 500px;
    
                    .sub__image{
    
                        width: 100%;
                        height: 180px;
                        background-color: #eee;
                        overflow: hidden;
                        border-radius: 10px;
    
                    }
    
                    .sub__title{
                        font-weight: 500;
                        line-height: 23px;
                        overflow: hidden;
                        font-size: 15px;
                        width: 90%;
                        flex-direction: column-reverse;
    
                        .date{
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
            }

        }

        .overhold{
            flex-direction: column;
        }

        .news_container{
            width: 100%;
            margin-bottom: 0;
            padding-bottom: 0;
            .current_news{
                .current_news_title{
                    
                    padding-right: 100px;
                    padding-bottom: 20px;
                }
            }
        }
        
    }
    
}

@media (max-width : 768px) {

    .view_news{

        .sub_news_container{
        }

        .overhold{
            flex-direction: column;
        }

        .news_container{
            width: 100%;
            margin-bottom: 0;
            .current_news{
                .current_news_title{
                    
                    padding-right: 0px;
                    padding-bottom: 20px;
                }
            }
        }
        
    }
    
}

@media (max-width : 500px) {

    .view_news{
        .overhold{
            margin-top: 105px;
        }
        .news_container{
            .current_news{
                .current_news_image{
                    height: 300px;
                }
                .current_news_title{
                    font-size: 30px;
                    line-height: 43px;
                    margin-bottom: 15px;
                }
            }
        }
    }
    
}