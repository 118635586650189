.recent_news{

    background-color: #fff;
    padding: 80px 0;
    padding-top: 125px;
    padding-bottom: 110px;

    .newsAreaHome{
        
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        .media{
            width: 52%;
            background-color: #e3e3e3;
            height: 600px;
            position: relative;
            border-radius: 20px;
            overflow: hidden;


            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .mintVideo{

                width: 100%;
                height: 600px;

                video{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .console{

                    width: 100%;
                    height: 600px;
                    background: -moz-linear-gradient(180deg, rgba(138,231,0,0) 0%, rgba(0,0,0,0.33519345238095233) 100%);
                    background: -webkit-linear-gradient(180deg, rgba(138,231,0,0) 0%, rgba(0,0,0,0.33519345238095233) 100%);
                    background: linear-gradient(180deg, rgba(138,231,0,0) 0%, rgba(0,0,0,0.33519345238095233) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8ae700",endColorstr="#000000",GradientType=1);
                    position: absolute;
                    bottom: 0;

                }

                .control{
                    width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: #fff;
                    position: absolute;
                    z-index: 999;
                    bottom: 0;
                    margin: 30px;
                    right: 0;
                    cursor: pointer;
                }

                .sound{
                    bottom: 0;
                }

                .play{
                    left: 0;
                }

            }
        }

        .content{

            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .tag{
                font-size: 13px;
                display: none;
            }

            .main{

                font-size: 40px;
                line-height: 55px;
                font-weight: 500 !important;

            }

            .sub{
                width: 65%;
                line-height: 25px;
                margin-bottom: 50px;
                margin-top: 40px;
            }

            .date_control{
                display: flex;
                justify-content: space-between;
                margin-top: auto;
                border-top: 1px solid #e3e3e3;
                padding-top: 40px;
                position: relative;

                .lineCheck{
                    
                    width: 100%;
                    height: 2px;
                    background-color: var(--lime);
                    position: absolute;
                    top: -2px;
                    
                    &.running{

                        animation: lineShock forwards;

                    }
                }

                @keyframes lineShock {
                    
                    from {
                        width: 100%;
                    }

                    to {
                        width: 0%;
                    }

                }

                .arrowOpp{
                    display: flex;
                    align-items: center;
                    gap: 25px;
                    
                    .arrowChange{

                        cursor: pointer;
                        
                        &:hover{
                            color: var(--lime);
                        }
                    }
                }
            }

        }

    }

}

@media (max-width : 1000px) {

    .recent_news{

        padding: 50px 0;
        
        .newsAreaHome{
            margin-top: 0;
            flex-direction: column-reverse;

            .media{
                width: 100%;
                max-width: 100%;
                height: 350px;

                .mintVideo{
                    height: 100%;
                    background-color: var(--secondary2);
                    max-width: 100%;

                    .console{
                        height: 100%;
                        max-width: 100%;
                    }
                }
            }

            .content{
                width: 100%;
                margin-top: 40px;

                .tag{
                    display: block;
                    margin-bottom: 30px;
                    font-size: 15px;
                    font-weight: 600;
                }

                .main{
                    font-size: 35px;
                    line-height: 45px;
                    position: relative;
                    max-height: calc(33.5px * 4);
                    overflow: hidden;
                }
            }
        }

    }

}

@media (max-width : 600px) {

    .recent_news{
        
        .newsAreaHome{

            .media{
                height: 230px;
            }

            .content{

                .tag{
                    font-size: 13px;
                    font-weight: 400;
                }

                .main{
                    font-size: 26px;
                    line-height: 36px;
                    max-height: calc(26.5px * 4);
                }

                .sub{

                    font-size: 14px;
                    line-height: 22px;
                    width: 100%;
                    margin-top: 20px;
                    margin-bottom: 30px;

                }

                .date_control{
                    padding-top: 30px;

                    .date{
                        font-size: 14px;
                    }
                }
            }
        }

    }

}