.slide__in{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #384D4E;
    transform-origin: bottom;
    z-index: 100;
}

.slide__out{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #384D4E;
    transform-origin: top;
    z-index: 100;
}

.fullPortion{
    height: 700px;
}