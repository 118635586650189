.rent{

    background-color: #fff;
    padding-bottom: 10px;
    padding-top: 10px;
    color: #000;

.news_card_body{

    padding : 60px 0;

    &.renty{

        padding-top: 90px;
        padding-bottom: 70px;
        position: relative;
        z-index: 50;

        a{
            color: #000;
        }

        .notion{

            font-size: 40px;
            text-align: center;
            font-weight: 600;

        }

        p{
            margin-top: 10px;
            text-align: center;
            margin-bottom: 80px;
            color: #999;
        }
    }

    .section_header{

        font-size: 20px;
        font-weight: 500;
        margin-bottom: 40px;

    }

    .news_results_section{

        display: flex;
        gap: 30px;
        overflow-x: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar{
            display: none !important;
        }

        .news_card{

            // width: 23%;
            min-width: 350px;
            cursor: pointer;
            border : none;
            padding: 0px;
            border-radius: 10px;
            // box-shadow: 0px 3px 20px rgba($color: #000000, $alpha: 0.04);

            .news_image{

                width: 100%;
                height: 200px;
                background-color: #eee;
                overflow: hidden;
                border-radius: 10px;

                img{

                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                    transition: all .4s ease-in-out;

                }

            }

            &:hover{
                
                .news_body_content{

                    .news_topic{

                        text-decoration: underline;

                    }
                    
                }

                .news_image{

                    img{
                        transform: scale(1.3);
                    }

                }
            }

            

            .news_body_content{

                margin-top: 20px;

                .date{
                    font-size: 12px;
                    margin-bottom: 8px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }

                .news_topic{
                    font-weight: 500;
                    line-height: 25px;
                    overflow: hidden;
                    height: 60px;
                    font-size: 16px;
                    width: 90%;
                }

                .news_details{

                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-top: 15px;

                    .details_themes{

                        text-transform: uppercase;
                        letter-spacing: 2px;
                        font-size: 11px;
                        background-color: var(--lasgGreen);
                        color: var(--primary);
                        padding: 3px 5px;
                        font-weight: bold;
                        border-radius: 3px;

                    }

                    .details_readTime{

                        display: flex;
                        align-items: center;
                        gap: 5px;
                        text-transform: uppercase;
                        font-size: 11px;
                        letter-spacing: 2px;
                    }

                }
            }

        }

    }
}

}