.events__body{

    padding-top: 160px;
    height: 100%;


    .events__body__title{

        font-size: 24px;
        font-weight: 500;
        color: #108a00;
        color: #131313;
        text-align: center;

    }

    .events{

        width: 100%;
        margin: 50px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .event__item{
            
            border-radius: 7px;
            width: 380px;
            overflow: hidden;
            background-color: #fff;
            box-shadow: 3px 1px 20px rgba($color: #000000, $alpha: 0.07);
            display: flex;
            flex-direction: column;

            .event__photo{

                height: 200px;
                width: 100%;
                overflow: hidden;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }

            .event__details{

                padding: 25px;

                .event__title{
                    font-size: 18px;
                    font-weight: 600;
                    margin: 10px 0;
                }

                .event__date{
                    font-weight: 500;
                    font-size: 12px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }

                .event__time{
                    display: flex;
                    // align-items: center;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 10px;
                    gap: 10px;
                    flex-direction: column;

                    .isPast{
                        background-color: #108a00;
                        padding: 3px;
                        border-radius: 3px;
                        color: #fff;
                        font-weight: 500;
                        width: max-content;
                    }
                }

                .rsvp{
                    border-top: 1px solid #eee;
                    padding-top: 20px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    margin-top: 20px;

                    &:hover{
                        color: #108a00;
                    }

                }

            }

        }

        .empty__events{
            
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 40px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 12px;
            font-weight: 500;

            .photoIcon{

                width: 60px;
                height: 60px;
                overflow: hidden;

                img{
                    width: 100%;
                }

            }

        }

    }

}

.arry{

    font-size: 13px;
    cursor: pointer;

}

.registerEvent{
    position: fixed;
    background-color: rgba($color: #000000, $alpha: .4);
    height: 100svh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;

    .registrationCard{
        background-color: #fff;
        margin-top: 170px;
        height: max-content;
        display: flex;
        flex-direction: column;
        width: 450px;
        padding: 30px;
        border-radius: 8px;
        gap: 30px;

        .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 12px;
            font-weight: 600;
            border-bottom: 1px solid #e3e3e3;
            padding-bottom: 20px;
        }

        form{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .formation{
                display: flex;
                flex-direction: column;
                gap: 7px;

                label{
                    font-size: 13px;
                    font-weight: 500;
                }

                input{
                    padding: 11px 10px;
                    outline: none;
                    border: none;
                    background-color: #f9f9f9;
                    border: 1px solid #eee;
                    border-radius: 5px;
                }
            }
        }

        .submitEvent{
            background-color: #108a00;
            margin-top: 15px;
            font-size: 15px;
            padding: 12px;
            border-radius: 5px;
            text-align: center;
            font-weight: 500;
            color: #fff;
            cursor: pointer;
        }
    }
}

.message{
    font-size: 15px;
}

@media(max-width : 1200px){

    .events__body{

        .events{

            flex-wrap: wrap;

        }
    }

}

@media(max-width : 500px){

    .events__body{
        padding-top: 100px;

        .events{
            margin-top: 30px;
        }
        
        .events__body__title{

            font-size: 20px !important;

        }
    }

}

.emptyTray{
    font-size: 11px;
    letter-spacing: 2px;
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -130px;
}
