.sideMenu{

    position: fixed;
    top: 120px;
    left: 0;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    z-index: 99;
    padding: 15px;
    scrollbar-width: none;

    p{
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 11px;

        &:hover{
            color: var(--primary25);
        }

        &:first-child{
            font-weight: 600;
            color: var(--primary);
            text-decoration: none;
            cursor: default;
            margin-right: 20px;
            gap: 10px;
        }
    }

}

.officials_body{

    padding: 250px 0;
    margin: 0px 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-bottom: 80px;

    &.bagElect{
        padding-top: 180px;
    }

    .topMan{

        .pageHeader{

            margin-bottom: 80px;
            min-width: 100%;
            gap: 30px;
            display: flex;
            align-items: center;

            .pageTitle{

                font-size: 55px;
                width: 80%;
                line-height: 65px;
                letter-spacing: -1px;
                font-weight: 600;
                text-align: center;

                span{
                    color: var(--primary25);
                    // font-style: italic;
                }

            }

            .pageTitleSubs{

                width: 600px;
                line-height: 26px;
                text-align: center;
                font-size: 16px;

            }

        }

    }

    .items{

        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        cursor: pointer;
        justify-content: center;
        margin-top: 0px;
    }

    .officialsPage{

        .photosPoint{

            .photoHolder{

                width: 360px;
        height: 440px;
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;

                .imageCarrier{

                    height: 390px;
            width: 100%;
            overflow: hidden;

            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: top;
                transition: all 0.8s cubic-bezier(0.39, 0.01, 0.36, 0.9);
            }

                    .trigger_anchor{
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        z-index: 9;
                        top: 0;
                        display: none;
                        align-items: center;
                        justify-content: center;
                        font-weight: 500;

                        p{

                            background-color: #009C3E;
                            color: #fff;
                            padding: 15px 20px;
                            border-radius: 100px;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            .icon{
                                font-size: 10px;
                                margin-top: 3px;
                            }

                        }
                    }

                    

                    &:hover{

                        background-color: #000000;

                        .trigger_anchor{
                            display: flex;
                        }
                        
                        img{
                            scale: 1.2;
                            opacity: .6;
                        }
                    }

                }

                .nameCarrier{

                    width: 85%;
            // height: 100px;
            background-color: #fff;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
            position: absolute;
            bottom: 0;
            padding: 20px;
            border-radius: 4px;
            transition: all 0.8s cubic-bezier(0.39, 0.01, 0.36, 0.9);
            text-align: center;

            .fullname{
                font-size: 18px;
                font-weight: 600;
                color: #131313;
                margin-top: 12px;
                
            }

            .position{
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 2px;
                color: #555;
                font-weight: 500;
                line-height: 18px;
            }

                }

            }

        }

    }
}


.viewport{

    width: 70%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;

    .official_details{

        display: flex;
        flex-direction: column;
        align-items: center;

        .official_name{

            font-size: 50px;
            font-weight: 700;
            color: var(--primary);
            text-align: center;
        }

        .post{
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 2px;
            font-weight: 600;
            margin-top: 20px;
            text-align: center;
            margin-bottom: 50px;
        }

        .shortBio{
            width: 60%;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            padding: 30px 0;
            border-top: 1px solid #c3d6bf;
            border-bottom: 1px solid #c3d6bf;
        }

    }

    .official_photo{

        width: 700px;
        height: 600px;
        background-color: #eee;
        margin-top: 50px;
        border-radius: 20px;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

    }

    .downloadPortrait{
        margin: 50px 0;
        font-weight: 500;
        color: var(--primary);
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }

    .official_highlights{

        width: 100%;
        margin-bottom: 60px;
        margin-top: 30px;

        .title{

            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 30px;
            letter-spacing: 2px;
        }

        .highlights{

            display: flex;
            gap: 10px;
            overflow: hidden;
            overflow-x: scroll;
            scrollbar-width: none;
            cursor: pointer;

            &::-webkit-scrollbar{
                display: none !important;
            }

            .highlight{
                height: 200px;
                min-width: 300px;
                background-color: #f9f9f9;
                overflow: hidden;
                border-radius: 10px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }

        }
    }

    .missionStatement{

        font-size: 28px;
        font-style: italic;
        color: var(--primary);
        margin-bottom: 50px;
        padding: 30px;
        border-radius: 10px;
        text-align: center;

        p{
            margin: 10px;
            font-weight: 600;
            font-size: 24px;
        }

    }

    .official_bio{

        font-size: 16px;
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        line-height: 26px;
        text-align: justify;
        .heading{
            font-weight: 600;
            color: var(--primary25);
            margin-bottom: 10px;
        }

        .imp_img{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
            height: 200px;
            margin-bottom: 30px;
            margin-top: 20px;

            .imageContainer{
                width: 50%;
                height: 100%;
                border-radius: 10px;
                overflow: hidden;
                background-color: #f9f9f9;
                display: flex;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

    }
}



@media(max-width : 1200px) {

    .sideMenu{

        position: fixed;
        top: 115px;
        left: 0;
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        padding: 15px 30px;
        overflow-x: scroll;
    
        p{
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 11px;
            min-width: max-content;
    
            &:first-child{
                font-weight: 600;
                color: var(--primary);
                text-decoration: none;
                cursor: default;
                margin-right: 20px;
                gap: 10px;
            }
        }
    
    }

    .officials_body{

        align-items: center;
        padding-top: 230px;

        &.bagElect{
            padding-top: 170px;
        }
    


        .topMan{
            .pageHeader{

                .pageTitle{
                    width: 100%;
                }
                
            }
        }

        .items{
            gap: 20px;
        }

        .officialsPage{
            
            width: 30%;

            .photosPoint{
                .photoHolder{
                    width: 100%;
                    .imageCarrier{
                        width: 100%;
                    }
                }
            }
        }
    }

    .viewport{
        width: 90%;
    }

}


@media(max-width : 1000px) {
    

    .officials_body{

        padding-top: 220px;

        .topMan{

            .pageHeader{

                gap: 20px;
                margin-bottom: 60px;

                .pageTitle{
                    width: 90%;
                }

                .pageTitleSubs{
                    width: 70%;
                }
            }
        }

        .officialsPage{
            
            width: 350px;

            .photosPoint{

                .photoHolder{

                    width: 100%;

                    .imageCarrier{

                        width: 100%;

                    }
                }
            }
        }
    }

}


@media(max-width : 850px) {
    

    .viewport{
        width: 100%;

        .official_details{
            .shortBio{
                width: 90%;
            }
        }
    }

    .officials_body{

        padding-top: 220px;

        .topMan{

            .pageHeader{

                gap: 20px;
                margin-bottom: 60px;

                

                .pageTitleSubs{
                    width: 100%;
                }
            }
        }

        .officialsPage{
            
            width: 48%;

            .photosPoint{
                .photoHolder{
                    width: 100%;
                    .imageCarrier{
                        width: 100%;
                    }
                }
            }
        }
    }

}

@media(max-width : 768px) {
    

    .viewport{
        width: 100%;

        .official_details{
            .shortBio{
                width: 90%;
            }
        }
    }

    .officials_body{

        padding-top: 220px;

        .topMan{

            .pageHeader{

                gap: 20px;
                margin-bottom: 60px;

                .pageTitle{
                    font-size: 50px;
                    line-height: 60px;
                }

                .pageTitleSubs{
                    width: 100%;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }

        .officialsPage{
            
            width: 46%;

            .photosPoint{
                .photoHolder{
                    width: 100%;
                    .imageCarrier{
                        width: 100%;
                    }
                }
            }
        }
    }

}

@media(max-width : 550px) {
    

    .viewport{
        width: 100%;

        .official_details{
            .shortBio{
                width: 90%;
            }
        }
    }

    .officials_body{

        // padding-top: 220px;
        
        &.bagElect{
            padding-top: 110px !important;
        }

        .topMan{

            .pageHeader{

                gap: 20px;
                margin-bottom: 60px;

                .pageTitle{
                    font-size: 40px;
                    line-height: 50px;
                }

                .pageTitleSubs{
                    width: 100%;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .officialsPage{
            
            width: 46%;

            .photosPoint{
                .photoHolder{
                    width: 100%;
                    .imageCarrier{
                        width: 100%;
                    }
                }
            }
        }
    }

}

@media(max-width : 500px) {

    .sideMenu{

        position: fixed;
        top: 70px;
        left: 0;
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        padding: 15px 30px;
        overflow-x: scroll;
    
        p{
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 11px;
            min-width: max-content;
    
            &:first-child{
                font-weight: 600;
                color: var(--primary);
                text-decoration: none;
                cursor: default;
                margin-right: 20px;
                gap: 10px;
            }
        }
    
    }

    .viewport{

        .official_details{

            .official_name{

                font-size: 40px;
                width: 70%;
                line-height: 45px;
            }

            .shortBio{
                width: 100%;
            }
        }

        .official_photo{
            width: 100%;
            height: 350px;
        }

        .official_highlights{
            margin-top: 10px;
            margin-bottom: 20px;

            .title{
                font-size: 12px;
                text-align: center;
            }
        }

        .missionStatement{
            font-size: 24px;
            margin-bottom: 20px;

            p{
                font-size: 16px;
            }
        }

        .official_bio{
            width: 100%;

            .imp_img{
                height: 180px;
            }
        }

    }

    .officials_body{

        padding-top: 190px;

        &.bagElect{

            padding-top: 130px;

        }

        .topMan{

            .pageHeader{

                gap: 20px;
                margin-bottom: 50px;
            }
        }

        .items{
            gap: 40px;
        }

        .officialsPage{
            
            width: 75%;

            .photosPoint{
                .photoHolder{
                    width: 100%;
                    .imageCarrier{
                        width: 100%;
                    }

                    .nameCarrier{
                        margin-top: 15px;
                
                    }
                }

                
            }
        }
    }

}

@media(max-width : 420px) {
    

    .viewport{
        width: 100%;

        .official_details{
            .shortBio{
                width: 90%;
            }
        }
    }

    .officials_body{

        padding-top: 220px;

        .topMan{

            .pageHeader{

                gap: 20px;
                margin-bottom: 60px;

                .pageTitle{
                    font-size: 35px;
                    line-height: 43px;
                }

                .pageTitleSubs{
                    width: 100%;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .officialsPage{
            
            width: 95%;

            .photosPoint{
                .photoHolder{
                    width: 100%;
                    .imageCarrier{
                        width: 100%;
                    }
                }
            }
        }
    }

}

@media(max-width : 350px) {

    .officials_body{

        .officialsPage{
            
            width: 100%;

        }
    }

}