.slider{

    background-color: #fafffc;
    background-color: #fcfff5;
    background-color: #fff;
    position: relative;
    overflow: hidden;

    .mobile__text{
        display: none;
    }

    .filters{
        opacity: 1;
        display: none;
    }

    .background__filters{
        height:1400px;
        width:1400px;
        position: fixed;
        top: -500px;
        left: -400px;

        img{
            width: 100%;
        }
    }

    .orange__part{
        height:1400px;
        width:1400px;
        position: fixed;
        top: -500px;
        right: -400px;

        img{
            width: 100%;
        }
    }

    .grain{

        height: 100vh;
        width: 100%;
        position: fixed;
        opacity: .1;
        display: none;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

    @keyframes move {

        100%, 90% {

            left: calc(100% + 300px);

        }

        0% {

            left: -110px;

        }

        60% {

            left: calc(100% + 300px);

        }
        
    }

    @keyframes move2 {

        0% {

            right: -110px;

        }

        100% {

            right: calc(100% + 300px);

        }
        
    }


    .mobile__flash__news{

        width: 100%;
        position: relative;
        display: flex;
        margin-top: 170px;
        display: none;
        
        .flashNews{

            font-size: 1.2rem;
            background-color: #fff3d8;
            padding: 5px 10px;
            color: var(--dark);
            font-weight: 600;
            width: max-content;
            border-radius: 5px;
            display: none;

            span{
                margin-right: 8px;
            }

            a {
                color: #108a00;
            }

        }
        
    }

    .lasg__illustration{

        position: relative;
        // height: 100svh;
        // max-height: 1080px;
        width: 100%;
        height: 300px;

        .lasg__illustration__road{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            min-width: 1750px;
            z-index: 6;
            background-color: #384D4E;

            img{
                width: 100%;
                margin: 0 auto;
            }
        }

        .lasg__illustration__theatre{
            position: absolute;
            bottom: -10px;
            right: -30px;
            width: 500px;
            z-index: 5;

            img{
                width: 100%;
            }
        }

        .lasg__illustration__water{
            position: absolute;
            bottom: 0;
            left: -10px;
            width: 100%;
            min-width: 1750px;
            z-index: 4;

            img{
                width: 100%;
                margin: 0 auto;
            }
        }

        .lasg__illustration__eko{
            position: absolute;
            bottom: -250px;
            right: 50%;
            width: 900px;
            z-index: 4;
            transform: translateX(50%);

            img{
                width: 100%;
                margin: 0 auto;
            }
        }

        .lasg__illustration__eyo{
            position: absolute;
            bottom: 30px;
            right: -150px;
            width: 350px;
            z-index: 4;

            img{
                width: 100%;
                margin: 0 auto;
            }
        }

        .lasg__illustration__station{
            position: absolute;
            bottom: -20px;
            left: -30px;
            z-index: 3;
            min-width: 2550px;

            img{
                width: 100%;
            }
        }

        .lasg__illustration__train{

            position: absolute;
            bottom: 131px;
            left: 0px;
            width: 700px;
            left: -700px;
            overflow: hidden;
            animation: lefttoright 15s ease-in-out infinite;
            z-index: 2;

            img{
                width: 100%;
            }

        }

        .motion__illustration__2{

            width: 130px;
            position: absolute;
            display: block;
            bottom: 104px;
            z-index: 3;
            right: -140px;
            animation: move2 10s linear infinite;
            transform: rotateY(180deg);

            img{
                width: 100%;
            }

        } 


        // Motion illustrations
        .motion__illustration{

            width: 160px;
            position: absolute;
            bottom: 0;
            z-index: 6;
            left: -200px;
            animation: lefttoright 10s ease-in-out infinite;

            img{
                width: 100%;
            }

        }

        .lasumbus{

            animation-delay: 5s;

        }

        .brt{
            width: 230px;
            animation-delay: 9s;
            left: -320px;
        }

        .lagride{

            animation-delay: 1s;

        }

        
    }

    @keyframes lefttoright {
        
        to{
            left: calc(100% + 300px);
        }
        
    }

    @keyframes r2l {
        
        to{
            left: calc(-100% - 300px);
        }
        
    }

    .photo_container{

        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        position: relative;
        z-index: 9;
        margin-top: 200px;

        .searchBox{

            width: 800px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .input__body{

                input, .typeing{

                    width: 700px;
                    outline: none;
                    border: none;
                    border-radius: 5px;
                    background-color: transparent;
                    border-right: none;
                    padding-left: 20px;
                    height: 100%;
                    position: relative;
                    z-index: 3;
                    color: #000;
                    border: 1px solid #eee;

                }

                .search__action__btn{

                    background-color: #1c3f3a;
                    background-color: #108a00;
                    height: 100%;
                    width: 130px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    cursor: pointer;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 11px;
                    letter-spacing: 2px;
                    font-weight: 600;
                    gap: 5px;
                }

                .typeing{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    z-index: 1;
                    color: #000;
                    left: 0;
                    background-color: #f9f9f9;
                    border: 1px solid #eee;

                }

                input:focus ~ .typeing{

                    display: none;

                }

                // width: 100%;
            
                height: 55px;
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                margin-top: 0px;
                background-color: transparent;
                gap: 10px;


            }

            .quickSearches{
                
                width: 100%;
                overflow: hidden;
                display: flex;
                gap: 10px;
                margin-top: 20px;
                align-items: center;
                padding: 0 30px;
                

                .tip{
                    min-width: max-content;
                    margin-right: 10px;
                    font-size: 11px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: #384D4E;
                    font-weight: 600;
                }

                .arrow__nav{

                    height: 35px;
                    width: 40px;
                    position: relative;
                    cursor: pointer;

                    .abs{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin-top: 3px;
                        transition: all .2s ease-in-out;
                    }

                    &.abs1:hover{

                        .abs{
                            margin-left: -2px;
                        }

                    }

                    &.abs2:hover{

                        .abs{
                            margin-left: 2px;
                        }

                    }
                }

                .pills__searches{
                    display: flex;
                    gap: 10px;
                    overflow-x: auto;
                    scroll-behavior: smooth;
                    scrollbar-width: none;

                    &::-webkit-scrollbar{
                        display: none !important;
                    }

                    &:hover{

                        mask-image: none;

                    }
                }

                p{
                    min-width: max-content;
                    font-size: 10.5px;
                    background-color: #fff;
                    border: 1px solid rgba($color: #000000, $alpha: 0.2);
                    padding: 10px 15px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    border-radius: 5px;
                    font-weight: 500;
                    cursor: pointer;

                    &:hover{
                        background-color: #009C3E;
                        border-color: #009C3E;
                        color: #fff;
                    }

                }
            }

        }

        .home__content{

            width: 70%;
            // width: 800px;
            padding-top: 0px;
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            z-index: 99;

            .title{

                font-size: 12px;
                letter-spacing: 2px;
                font-weight: 500;
                color: #fff;
                color: #131313;
                text-transform: uppercase;
                margin-top: 0px;
                margin-bottom: 40px;

                span{
                    color: #108a00;
                }

            }

            .text__area{

                font-size: 70px;
                font-weight: 600;
                line-height: 60px;
                letter-spacing: -1px;
                color: #000;
                width: 100%;
                transition: all .4s ease-in-out;
                text-align: center;

                .many{
                    padding: 0;
                    padding-bottom: 18px;

                    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7){
                        color: #108a00;
                    }
                }

                .welcome{
                    font-family: "EB Garamond", serif;
                    font-weight: 400;
                    letter-spacing: 0;
                    font-style: italic;
                    font-size: 21px;
                    color: #333;
                    margin-bottom: 20px;
                }

                span{
                    color: green;
                }

                .holder{
                    margin-bottom: -20px;
                }

            }

            .text__area_sub{

                width: 500px;
                line-height: 25px;
                margin-top: 35px;
                font-weight: 400;

            }

        }

    }

    .trigger{

        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        align-items: center;
        gap: 4px;
        position: relative;
        z-index: 4;
        color: #131313;
        width: max-content;
        cursor: pointer;
        margin-bottom: 50px;

        &:hover{
            text-decoration: underline;
        }

        span{
            display: flex;
            gap: 10px;
            color: #108a00;
            font-weight: 700;
            align-items: center;
            margin-right: 25px;
        }

        .icon{

            font-size: 10px;
            margin-top: -4px;
            animation: upDownBounce .4s ease-in-out infinite;
            position: absolute;
            right: 0;

        }


    }

}

@keyframes upDownBounce {

    0%{
        margin-top: -4px;
    }

    50%{
        margin-top: 4px;
    }

    100%{
        margin-top: -4px;
    }
}


// @media (max-height : 860px) {

//     .slider{

//         .trigger{
//             margin-top: 40px;
//         }

//         .lasg__illustration{
//             .lasg__illustration__eko{
//                 bottom: -260px;
//             }
//         }
//     }

// }

// @media (max-height : 830px) {

//     .slider{

//         .trigger{
//             margin-top: 35px;
//         }

//         .lasg__illustration{
//             .lasg__illustration__eko{
//                 bottom: -290px;
//             }
//         } 
//     }

// }

// @media (max-height : 770px) {

//     .slider{

//         .trigger{
//             margin-top: 40px;
//             display: none;
//         }

//         .lasg__illustration{
//             .lasg__illustration__eko{
//                 bottom: -290px;
//             }
//         } 
//     }

// }

// @media (max-height : 750px) {

//     .slider{

//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         .trigger{
//             display: flex;
//         }

//         .lasg__illustration{
//             display: none;
//         } 

//         .photo_container{
//             margin-top: 80px !important;
//         }
//     }

// }

@media (max-width : 1680px) {

    .slider{
        .photo_container{

            .home__content{
                width: 75%;

                .text__area{
                    font-size: 60px;
                    line-height: 53px;
                }
            }
        }
    }

}

// @media (max-width : 1380px) {

//     .slider{
//         .photo_container{

//             .searchBox{
//                 width: 60%;
//             }

//             .home__content{
//                 width: 90%;
//             }
//         }
//     }

// }


@media (max-width : 1200px) {

    .slider{
        
        .photo_container{

            margin-top: 160px;
            gap: 30px;

            .searchBox{
                width: 80%;
            }
    
            .home__content{

                width: 85%;
    
                .text__area{

                    font-size: 55px;
                    line-height: 48px;

                    .holder{
                        margin-bottom: -19px;
                    }
    
                    span{
                        color: #fff;
                        color: #108a00;
                        text-decoration: underline;
                    }
    
                }
    
                .text__area_sub{
    
                    width: 400px;
                    line-height: 25px;
                    margin-top: 40px;
                    font-weight: 400;
    
                }
    
            }
    
        }

        .trigger{

            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 2px;
            margin-top: 40px;
            display: flex;
            align-items: center;
            gap: 4px;
            position: relative;
            z-index: 4;
            color: #131313;
            width: max-content;
            cursor: pointer;
    
            &:hover{
                text-decoration: underline;
            }
    
            span{
                display: flex;
                gap: 10px;
                color: #108a00;
                font-weight: 700;
                align-items: center;
                margin-right: 25px;
            }
    
            .icon{
    
                font-size: 10px;
                margin-top: -4px;
                animation: upDownBounce .4s ease-in-out infinite;
                position: absolute;
                right: 0;
    
            }
    
    
        }

    }

}

@media (max-width : 1024px){

    .slider{
        
        .photo_container{

            margin-top: 170px;

            .searchBox{
                width: 90%;

                .input__body{

                    width: 100%;

                    input, .typeing{
                        width: calc(100% - 140px);
                    }
                }
            }

            .home__content{
                margin-top: 0px;
                width: 100%;
                .text__area{
                    font-size: 50px;
                    line-height: 45px;
                }
            }
        }

        .lasg__illustration{
            .lasg__illustration__theatre{
                width: 400px;
            }

            .lasg__illustration__eyo{
                width: 300px;
            }

            .lasg__illustration__station{
                bottom: 20px;
                min-width: 2000px;
            }

            .lasg__illustration__train{

                width: 600px;
                left: -600px;
    
                img{
                    width: 100%;
                }
    
            }

            .lasg__illustration__eko{
                bottom: -250px;
            }
        }
    }

}

@media (max-width : 950px) {

    .slider{

        .photo_container{
            width: 100%;
    
            .home__content{
    
                .text__area{
    
                    span{
                        color: #fff;
                        color: #108a00;
                        text-decoration: underline;
                    }
    
                }
    
            }
    
        }

    }

}


@media (max-width : 750px) {

    .slider{

        .filters{
            height: 1200px;
            width: 1200px;
        }
        
        .lasg__illustration{

            .lasg__illustration__road{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                min-width: 1750px;
                z-index: 4;
                background-color: #384D4E;
    
                img{
                    width: 100%;
                    margin: 0 auto;
                }
            }

            .lasg__illustration__eyo{
                bottom: -70px;
                z-index: 3;
            }

            .lasg__illustration__water{

                position: absolute;
                bottom: -28px;
                left: -10px;
                width: 100%;
                min-width: 1750px;
                z-index: 3;
    
                img{
                    width: 100%;
                    margin: 0 auto;
                }
            }

    
            .lasg__illustration__theatre{
                position: absolute;
                bottom: -10px;
                right: -90px;
                width: 850px;
                z-index: 2;
                display: none;
    
                img{
                    width: 100%;
                }
            }
    
            .lasg__illustration__clouds{
                position: absolute;
                top: 180px;
                right: 100px;
                width: 200px;
                z-index: 2;
    
                img{
                    width: 100%;
                }
            }
    
            .lasg__illustration__clouds_2x{
                position: absolute;
                top: 300px;
                right: 10px;
                width: 200px;
    
                img{
                    width: 100%;
                }
            }
    
            .lasg__illustration__eko{
                position: absolute;
                bottom: -180px;
                left: 50%;
                width: 800px;
                z-index: 3;
                transform: translateX(-50%);

    
                img{
                    width: 100%;
                    margin: 0 auto;
                }
            }

            .lasg__illustration__station{
                z-index: 2;
            }

            .lasg__illustration__train{
                z-index: 1;
            }

            .motion__illustration__2{

                width: 100px;
                position: absolute;
                display: block;
                bottom: 76px;
                z-index: 2;
                right: -100px;
                animation: move2 10s linear infinite;
                transform: rotateY(180deg);
    
                img{
                    width: 100%;
                }
    
            } 

            // motion side
            .motion__illustration{

                width: 130px;
                position: absolute;
                bottom: 0;
                z-index: 5;
                left: -130px;
                animation: lefttoright 10s ease-in-out infinite;
    
                img{
                    width: 100%;
                }
    
            }
    
            .lasumbus{
    
                animation-delay: 2s;
    
            }
    
            .brt{
                width: 190px;
                animation-delay: 4s;
                left: -190px;
            }
    
            .lagride{
    
                animation-delay: 6s;
    
            }
            
        }

        .photo_container{

            width: 100%;
            display: flex;

            .searchBox{
                width: 90%;
            }
            
    
            .home__content{
    
                width: 100%;
                padding: 0px;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                z-index: 99;
    
                .title{
    
                    font-size: 12px;
                    letter-spacing: 2px;
                    font-weight: 500;
                    color: #fff;
                    color: #131313;
                    text-transform: uppercase;
                    margin-top: 0px;
                    margin-bottom: 35px;
    
                    span{
                        color: #108a00;
                    }
    
                }
    
                .text__area{
    
                    width: 100%;
                    font-size: 45px;
                    line-height: 38px;
    
                    span{
                        color: #fff;
                        color: #108a00;
                        text-decoration: underline;
                    }
    
                }
    
                .text__area_sub{
    
                    width: 400px;
                    line-height: 25px;
                    margin-top: 40px;
                    font-weight: 400;
    
                }
    
            }
    
        }

        .trigger{

            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 2px;
            margin-top: 40px;
            display: flex;
            align-items: center;
            gap: 4px;
            position: relative;
            z-index: 4;
            color: #131313;
            width: max-content;
            cursor: pointer;
    
            &:hover{
                text-decoration: underline;
            }
    
            span{
                display: flex;
                gap: 10px;
                color: #108a00;
                font-weight: 700;
                align-items: center;
                margin-right: 25px;
            }
    
            .icon{
    
                font-size: 10px;
                margin-top: -4px;
                animation: upDownBounce .4s ease-in-out infinite;
                position: absolute;
                right: 0;
    
            }
    
    
        }

    }

}


@media (max-width : 600px) {

    .slider{

        .photo_container{

            width: 100%;
            display: flex;

            .searchBox{
                width: 100%;

                .quickSearches{

                    padding: 0;
                }
            }
    
            .home__content{
    
                width: 100%;
                padding: 0px;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                z-index: 99;
    
                .text__area{

                    font-size: 40px;
                    line-height: 33px;

                    .holder{
                        margin-bottom: -22px;
                    }
    
                    span{
                        color: #fff;
                        color: #108a00;
                        text-decoration: underline;
                    }
    
                }
    
                .text__area_sub{
    
                    width: 400px;
                    line-height: 25px;
                    margin-top: 40px;
                    font-weight: 400;
    
                }
    
            }
    
        }

        .trigger{

            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 2px;
            margin-top: 40px;
            display: flex;
            align-items: center;
            gap: 4px;
            position: relative;
            z-index: 4;
            color: #131313;
            width: max-content;
            cursor: pointer;
    
            &:hover{
                text-decoration: underline;
            }
    
            span{
                display: flex;
                gap: 10px;
                color: #108a00;
                font-weight: 700;
                align-items: center;
                margin-right: 25px;
            }
    
            .icon{
    
                font-size: 10px;
                margin-top: -4px;
                animation: upDownBounce .4s ease-in-out infinite;
                position: absolute;
                right: 0;
    
            }
    
    
        }

    }

}




@media (max-width : 550px) {

    .slider{

       
        
        .lasg__illustration{
    
            .lasg__illustration__theatre{
                position: absolute;
                bottom: -10px;
                right: -90px;
                width: 750px;
                z-index: 2;
                display: none;
    
                img{
                    width: 100%;
                }
            }
    
            .lasg__illustration__clouds{
                position: absolute;
                top: 180px;
                right: 100px;
                width: 200px;
                z-index: 2;
                display: none;
    
                img{
                    width: 100%;
                }
            }
    
            .lasg__illustration__clouds_2x{
                position: absolute;
                top: 300px;
                right: 10px;
                width: 200px;
                z-index: 2;
                display: none;
    
                img{
                    width: 100%;
                }
            }

            // motion side
            .motion__illustration{

                width: 140px;
                position: absolute;
                bottom: 0;
                z-index: 5;
                left: -140px;
                animation: lefttoright 10s ease-in-out infinite;
    
                img{
                    width: 100%;
                }
    
            }
    
            .lasumbus{
    
                animation-delay: 2s;
    
            }
    
            .brt{
                width: 200px;
                animation-delay: 4s;
                left: -200px;
            }
    
            .lagride{
    
                animation-delay: 6s;
    
            }
            
        }

        .photo_container{

            width: 95%;
            display: flex;
            margin: 0 auto;
            margin-top: 160px;
            gap: 20px;
    
            .home__content{
    
                width: 100%;
                padding: 0px;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                z-index: 99;

                .text__area{
                    width: 100%;
                    font-weight: 600;
                    line-height: 33px;
                }
    
               
    
                .text__area_sub{
    
                    width: 400px;
                    line-height: 25px;
                    margin-top: 40px;
                    font-weight: 400;
    
                }
    
            }
    
        }

    }

}

@media (max-width : 500px) {

    .slider{
    

        .emergencyField{
            display: none;
        }

        .lasg__illustration{
            height: 200px;

            .lasg__illustration__eyo{
                display: none;
            }

            .lasg__illustration__road{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                min-width: 1750px;
                z-index: 4;
                background-color: #384D4E;
    
                img{
                    width: 100%;
                    margin: 0 auto;
                }
            }

            .lasg__illustration__station{
                display: none;
            }

            .lasg__illustration__train{

                bottom: 76px;
                width: 550px;
                animation: r2l 15s linear infinite;
                // animation: none;
                left: 100%;
                transform: rotateY(180deg);
            }

            .lasg__illustration__water{

                position: absolute;
                bottom: -28px;
                left: -10px;
                width: 100%;
                min-width: 1750px;
                z-index: 1;
    
                img{
                    width: 100%;
                    margin: 0 auto;
                }
            }

            .lasg__illustration__eko{
                position: absolute;
                bottom: -185px;
                left: 50%;
                transform: translateX(-50%);
                width: 600px;
                z-index: 3;
    
                img{
                    width: 100%;
                    margin: 0 auto;
                }
            }

            // motion side
            .motion__illustration{

                width: 110px;
                position: absolute;
                bottom: 0;
                z-index: 5;
                left: -110px;
                animation: move 10s linear infinite;
    
                img{
                    width: 100%;
                }
    
            }

            .motion__illustration__2{

                width: 80px;
                position: absolute;
                display: none;
                bottom: 76px;
                z-index: 2;
                right: -80px;
                animation: move2 10s linear infinite;
                transform: rotateY(180deg);
    
                img{
                    width: 100%;
                }
    
            }    
    
            .brt{
                width: 170px;
                animation-delay: 3s;
                left: -170px;
            }
    
            .lagride{
    
                animation-delay: 6s;
    
            }
            
        }

        .photo_container{

            gap: 30px;
            margin-top: 130px;

            .home__content{
    
                width: 100%;
                padding: 0px;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                z-index: 99;
    
                .title{
    
                    font-size: 12px;
                    letter-spacing: 2px;
                    font-weight: 500;
                    color: #fff;
                    color: #131313;
                    text-transform: uppercase;
                    margin-top: 0px;
                    margin-bottom: 35px;
    
                    span{
                        color: #108a00;
                    }
    
                }
    
                .text__area{

                    font-size: 35px;
                    line-height: 28px;
                    width: 100%;

                    .welcome{
                        font-family: "EB Garamond", serif;
                        font-weight: 400;
                        letter-spacing: 0;
                        font-style: italic;
                        font-size: 21px;
                        color: #333;
                        margin-bottom: 10px;
                    }

                    .holder{
                        margin-bottom: -14.5px;
                    }
    
                    span{
                        color: #fff;
                        color: #108a00;
                        text-decoration: none;
                    }
    
                }
    
                .text__area_sub{
    
                    width: 400px;
                    line-height: 25px;
                    margin-top: 40px;
                    font-weight: 400;
    
                }
    
            }
    
        }

    }

}



@media (max-width : 450px) {

    .slider{

        .photo_container{
            margin-top: 120px;

            .searchBox{

                .input__body{

                    flex-direction: column;
                    height: max-content;
                    align-items: center;

                    .typeing{
                        top: 0;
                        transform: translateY(0);
                    }
                    
                    input, .typeing, .search__action__btn{

                        width: 100%;
                        height: 55px;

                    }
                }
            }
    
            .home__content{
    
                width: 100%;
                padding: 0px;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                z-index: 99;
                width: 100%;
    
                .title{
    
                    font-size: 12px;
                    letter-spacing: 2px;
                    font-weight: 500;
                    color: #fff;
                    color: #131313;
                    text-transform: uppercase;
                    margin-top: 0px;
                    margin-bottom: 35px;
    
                    span{
                        color: #108a00;
                    }
    
                }
    
                .text__area{

                    font-size: 32px;
                    line-height: 22px;
                    letter-spacing: -0.5px !important;

                    .mobile__text{
                        text-align: left;
                    }

                    .holder{
                        margin-bottom: -9.5px;
                    }
    
                    span{
                        color: #fff;
                        color: #108a00;
                    }
    
                }
    
            }
    
        }

    }

}

@media (max-width : 400px) {

    .slider{

        .photo_container{
    
            .home__content{
    
                .text__area{

                    letter-spacing: -.5px;

                    .holder{
                        margin-bottom: -13px;
                    }
    
                    span{
                        color: #fff;
                        color: #108a00;
                    }
    
                }
    
            }
    
        }

    }

}




