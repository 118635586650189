.news_body{

    background-color: #FDFDFD;

    .tweets{
        background-color: red;
    }

    .news{

        padding-bottom : 0px;

        .news_tag{

            position: fixed;
            top: 120px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            width: 100%;
            display: flex;
            align-items: center;
            gap: 40px;
            z-index: 99;
            background-color: #fff;
            overflow-x: auto;
            scrollbar-width: none;
            cursor: pointer;

            .containerWrap{
                align-items: center;
            }

            .newsTopic{

                gap: 30px;
                height: 50px;
                align-items: center;
                scroll-snap-type: mandatory;
                scroll-behavior: smooth;
                overflow-x: auto;
                scrollbar-width: none;
                margin: 0 20px;

                &::-webkit-scrollbar{
                    display: none !important;
                }

            }

            .news_tagging{

                font-size: 11px;
                letter-spacing: 2px;
                text-transform: uppercase;
                width: max-content;
                flex-shrink: 0;
                height: 100%;
                display: flex;
                align-items: center;

                &.active{

                    color: var(--primary25);
                    font-weight: 700;

                }

            }
        }

        .noNews{

            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: 12px;

            span{
                color: #009C3E;
                font-weight: 500;
            }

        }

        .landingNews{

            display: flex;
            gap: 20px;
            margin-top: 0px;

            .mainHighlight{

                height: 800px;
                width: 100%;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: flex-end;

                &:hover{
                    .highlight_content{
                        .highlight_title{
                            text-decoration: underline;
                        }
                    }
                }

                .overlay{

                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: 9;
                    background: rgb(0,0,0);
                    background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
                    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
                    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
                    
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

                }

                .highlight_image{

                    position: absolute;
                    height: 100%;
                    width: 100%;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position:top;
                    }

                }

                .contentArea__news{

                    display: flex;
                    height: max-content;

                }

                .highlight_content{

                    position: relative;
                    padding: 70px 50px;
                    height: 100%;
                    z-index: 91;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    color: #fff;
                    width: 60%;

                    .highlight__short{
                        width: 80%;
                        margin-top: 30px;
                        line-height: 25px;
                        margin-bottom: 10px;
                    }

                    .date{
                        margin-bottom: 10px;
                    }

                    .info{

                        display: flex;
                        align-items: center;
                        gap: 20px;
                        font-size: 14px;

                        .highlight_theme{

                            text-transform: uppercase;
                            letter-spacing: 2px;
                            font-size: 11px;
                            background-color: var(--lasgGreen);
                            color: var(--primary);
                            padding: 3px 5px;
                            font-weight: bold;
                            border-radius: 3px;

                        }

                        .timeToRead{

                            display: flex;
                            align-items: center;
                            gap: 5px;
                            text-transform: uppercase;
                            font-size: 11px;
                            letter-spacing: 2px;
                        }

                        margin-top: 20px;

                    }

                    .highlight_title{
                        font-size: 35px;
                        line-height: 47px;
                        font-weight: 600;
                        text-decoration: underline;
                    }
                    
                }

            }

        }

        .holderNewsBar{
            background-color: #fff;
            position: relative;
            padding-bottom: 50px;
            padding-top: 50px;
        }
        
        .news_card_body{

            padding: 0;
            margin-top: 0px;

            .news_results_section{

                margin-top: 0px;
                // margin-bottom: 100px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 30px;

                a{
                    color: #000;
                }

                .noNewNews{

                    text-align: center;
                    width: 100%;
                    margin-top: 60px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    font-size: 12px;

                    span{
                        color: #009C3E;
                        font-weight: 500;
                    }
                }

                .news_card{

                    width: calc(25% - 25px);
                    cursor: pointer;
                    border : none;
                    padding: 0px;
                    border-radius: 10px;
                    // box-shadow: 0px 3px 20px rgba($color: #000000, $alpha: 0.04);

                    .news_image{

                        width: 100%;
                        height: 200px;
                        background-color: #eee;
                        overflow: hidden;
                        border-radius: 10px;

                        img{

                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: top;
                            transition: all .4s ease-in-out;

                        }
                    }

        
                    &:hover{
                        
                        .news_body_content{
        
                            .news_topic{
        
                                text-decoration: underline;
        
                            }
                            
                        }
        
                        .news_image{
        
                            img{
                                transform: scale(1.3);
                            }
        
                        }
                    }
        
                    .news_body_content{
        
                        margin-top: 15px;
                        color: #000;

                        .date{
                            font-size: 13px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
        
                        .news_topic{
                            font-weight: 600;
                            line-height: 23px;
                            overflow: hidden;
                            height: 50px;
                            font-size: 15px;
                        }
        
                        .news_details{
        
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            margin-top: 15px;
        
                            .details_themes{
        
                                text-transform: uppercase;
                                letter-spacing: 2px;
                                font-size: 11px;
                                background-color: var(--lasgGreen);
                                color: var(--primary);
                                padding: 3px 5px;
                                font-weight: bold;
                                border-radius: 3px;
        
                            }
        
                            .details_readTime{
        
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                text-transform: uppercase;
                                font-size: 11px;
                                letter-spacing: 2px;
                            }
        
                        }
                    }
        
                }

            }
        }
    
    }

}


@media (max-width : 1500px) {

    .news_body{

        .news{

            .news_card_body{
                
                .news_results_section{

                    .news_card{
                        width: calc(33.3% - 25px);
                    }
                }
            }
        }
    }
    
}

@media (max-width : 1200px) {

    .news_body{

        .news{

            .news_tag{

                position: fixed;
                top: 115px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                width: 100%;
                display: flex;
                align-items: center;
                gap: 40px;
                z-index: 99;
                background-color: #fff;
                overflow-x: auto;
                scrollbar-width: none;
                cursor: pointer;
    
                .containerWrap{
                    align-items: center;
                }
    
                .newsTopic{
    
                    gap: 0px;
                    height: 50px;
                    align-items: center;
                    scroll-snap-type: mandatory;
                    scroll-behavior: smooth;
                    overflow-x: auto;
                    scrollbar-width: none;
                    margin: 0 20px;
    
                }
    
                .news_tagging{
    
                    font-size: 11px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    width: max-content;
                    flex-shrink: 0;
                    height: max-content;
                    padding: 10px 15px;
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
    
                    &.active{
    
                        color: var(--primary25);
                        font-weight: 700;
                        background-color: #1c3f3a;
                        background-color: #009C3E;
                        color: #fff;
    
                    }
    
                }
            }

            .landingNews{

                .mainHighlight{

                    .highlight_content{
                        width: 80%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

            }

        }
    }    

}

@media (max-width : 1000px) {

    .news_body{

        .news{

            .landingNews{

                margin-top: 160px;

                .mainHighlight{
                    height: 450px;

                    .highlight_image{
                        img{

                        object-position: 0 0;
                        }
                    }
                    .highlight_content{
                        .highlight_title{
                            width: 100%;
                        }
                    }
                }

            }

            .news_card_body{
                
                .news_results_section{
                    gap: 30px 20px;

                    .news_card{
                        width: calc(50% - 20px);

                        &:hover{
                        
                            .news_body_content{
            
                                .news_topic{
            
                                    text-decoration: none;
            
                                }
                                
                            }
            
                            .news_image{
            
                                img{
                                    transform: scale(1);
                                }
            
                            }
                        }
                    }
                }
            }
        }
    }
    
}

@media (max-width : 768px) {

    .news_body{

        .news{
            .landingNews{

                .mainHighlight{
    
                    .highlight_content{
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
    
            }
        }
        
    }
    
}

@media (max-width : 700px) {

    .news_body{

        .news{
            .landingNews{

                .mainHighlight{

                    height: 500px;
    
                    .highlight_content{
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
    
            }
        }
        
    }
    
}

@media (max-width : 500px) {

    .news_body{

        .news{
            .news_tag{

                top: 70px;

            }

            .landingNews{

                margin-top: 0px;

                .mainHighlight{

                    height: 90vh;

                    .highlight_content{
                        padding: 40px 20px;
                        .highlight_title{
                            width: 100%;
                            font-size: 30px;
                            line-height: 37px;
                        }

                        .highlight__short{
                            width: 100%;
                            font-size: 15px;
                        }
                    }
                }

            }

            .holderNewsBar{
                padding: 0px 0;
            }

            .news_card_body{

                margin-top: 15px;
                
                .news_results_section{
                    gap: 20px;

                    .news_card{
                        width: 100%;
                    }
                }
            }
        }
    }
    
}