.services{

    padding: 90px 0;
    padding-bottom: 50px;

    &.server{
        padding-top: 50px;
    }

    .midTopic{
        
        margin: 0px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 90px;
        color: #131313;

        .topic{
            font-size: 50px;
            line-height: 55px;
            letter-spacing: -1px;
            color: #131313;
            margin-bottom: 30px;
            font-weight: 600;
            text-align: center;
        }

        .subtitle{
            letter-spacing: 2px;
            font-size: 13px;
            font-weight: 500;
        }

    }

    .view_all{
        background-color: #1c3f3a;
        padding: 20px 30px;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #fff;
        margin: 50px auto;
        margin-bottom: 10px;
        cursor: pointer;
        width: max-content;
        border-radius: 8px;
    }

    .servicesPlatoon{
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        width: 100%;

        &.servicePagePlatoon{

            .services_card{

                box-shadow: 0px 3px 30px rgba($color: #000000, $alpha: .02);
                border: 1px solid #eee;

                &:hover{

                    box-shadow: none;
                    background-color: #009C3E;
                    
                    .title, .subtext{
                        color: #fff;
                    }

                    .icon{
                        background-color: #fff;
                        border-radius: 50%;
                        flex-shrink: 0;
                    }

                }
            }

        }
    }

    .services_card{

        width: calc(25% - 20px);
        //max-width: 280px;
        background-color: #fff;
        padding: 35px 0px;
        height: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        transition: all .4s ease-in-out;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 0px 3px 30px rgba($color: #000000, $alpha: .02);
        border: 1px solid #eee;

        .icon{
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;

            .hov{
                position: absolute;
                transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }

            .hov1{

                right: 50%;
                translate: 50%;
                
            }

            .hov2{

                left: -60px;
                
            }

        }

        &:hover{

            border-color: #009C3E;

            .icon{
    
                .hov1{
                    right: calc(-100% + 30px);
                }
    
                .hov2{
                    left: 50%;
                    translate: -50%;
                }
            }

            .title{
                color: #009C3E;
            }

            .subtext{
                text-decoration: underline;
            }

        }

        .title{
            font-size: 18px;
            color: var(--dark);
            margin-bottom: auto;
            margin-top: 20px;
            font-weight: 700;
            text-align: center;
            width: 70%;
        }

        .subtext{
            font-size: 14px;
            color: #333;
            text-align: center;
            width: 80%;
            line-height: 21px;
        }

    }
}



@media (max-width : 1000px) {

   .services{
    padding: 70px 0;

    .midTopic{

        text-align: center;
        margin-bottom: 50px;
        align-items: flex-start;

        .topic{
            font-size: 45px;
            text-align: left;
            width: 70%;
            line-height: 50px;
        }

        .subtitle{
            line-height: 24px;
            font-size: 12px;
        }

    }

    .servicesPlatoon{
        gap: 20px;
    }

    .services_card{

        width: calc(33% - 11px);  

    }

   }

}

@media (max-width : 820px) {

    .services{

     .servicesPlatoon{

        &.servicePagePlatoon{

            .services_card{

                &:hover{

                    background-color: #fff;
                    box-shadow: 0px 3px 20px rgba($color: #000000, $alpha: .03);
                    
                    .title, .subtext{
                        color: #000;
                    }

                    .icon{
                        background-color: #fff;
                        border-radius: 50%;
                        flex-shrink: 0;
                    }

                }
            }

        }

     }
 
     .midTopic{

        .subtitle{
            text-align: left;
        }
 
 
     }
 
     .services_card{

        &:hover{

            .icon{
    
                .hov1{

                    right: 50%;
                    translate: 50%;
                    
                }
    
                .hov2{
    
                    left: -60px;
                    
                }
            }

        }
        
        .title{
            width: 100%;
        }

        .subtext{

            max-height: calc(16px * 4 );
            overflow: hidden;
        }

     }
 
    }
 
 }

 @media (max-width : 758px) {

    .services{
 
     .midTopic{

        .topic{
            width: 100%;
        }
 
     }

     .servicesPlatoon{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
     }
 
     .services_card{

        width: 48%;  
        height: 320px;
        padding: 50px 10px;
        
        .title{
            width: 75%;
        }

        .subtext{

            max-height: calc(16px * 4 );
            overflow: hidden;
        }

     }
 
    }
 
 }

 @media (max-width : 600px) {

    .services{
 
     .midTopic{
 
         .topic{
             font-size: 38px;
             width: 100%;
             line-height: 43px;
         }
 
     }

     .servicesPlatoon{
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
     }
 
     .services_card{

        width: 100%;  
        height: 320px;
        padding: 50px 10px;
        
        .title{
            width: 75%;
        }

        .subtext{

            max-height: calc(16px * 4 );
            overflow: hidden;
        }

     }
 
    }
 
 }

 @media (max-width : 485px) {
 
     .services_card{

        width: 100% !important;  

     }
 
 }