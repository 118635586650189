.useCard{
    padding:0;
    width: 30%;
    text-align: center;
    border-radius: 100px;
    position: relative;
  }
  
  @property --angle{
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  
  .useCard::after, .useCard::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: conic-gradient(from var(--angle), #ff4545, #00ff99, #006aff, #ff0095, #ff4545);
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: -1;
    padding: 3px;
    border-radius: 100px;
    animation: 3s spin linear infinite;
  }
  // .useCard::before{
  //   filter: blur(1.5rem);
  //   opacity: 0.5;
  // }
  @keyframes spin{
    from{
      --angle: 0deg;
    }
    to{
      --angle: 360deg;
    }
}


@media (max-width : 1300px) {

    .useCard{

        width: 35%;

    }

}

@media (max-width : 1200px) {

    .useCard{

        width: 50%;

    }

}

@media (max-width : 600px) {

    .useCard{

        width: 90%;

    }

}