.search__component{

    background-color: #fafffc;
    background-color: #f9f9f9;
    height: 100vh;
    z-index: 999999;
    position: fixed;
    width: 100%;
    top: 0;
    padding-top: 50px;
    overflow-y: auto;

    .close__search__modal{

        position: fixed;
        right: 85px;
        font-size: 35px;
        top: 50px;
        cursor: pointer;
    }
}

.loader{
    margin: 0 auto;
}

.feedback{
    padding: 20px;
}

.search__title{

    width: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 40px;
    
    span{
        color: #108a00;
    }

    .top{
        font-size: 40px;
        font-weight: 600;
        letter-spacing: -1px !important;
        line-height: 50px;
        color: #353535;
        width: 60%;
        text-align: center;
    }

    .sub {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;
        margin-bottom: 10px;
        align-items: center;
        gap: 10px;

        span{
            gap: 5px;
            .searchIcon{
                font-size: 10px;
            }
        }

        .line{
            height: 15px;
            width: 1.5px;
            background-color: #999;
        }
    }

}

.search__field{

    width: 100%;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #f9f9f9;
    background: rgb(249,249,249);
    background: -moz-linear-gradient(180deg, rgba(249,249,249,0) 0%, rgba(249,249,249,1) 31%, rgba(249,249,249,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(249,249,249,0) 0%, rgba(249,249,249,1) 31%, rgba(249,249,249,1) 100%);
    background: linear-gradient(180deg, rgba(249,249,249,0) 0%, rgba(249,249,249,1) 31%, rgba(249,249,249,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f9f9f9",endColorstr="#f9f9f9",GradientType=1);
    height: 120px;
    gap: 10px;

    input{
        
        outline: none;
        background-color: transparent;
        border: none;
        position: relative;
        font-size: 16px;
        padding: 20px 30px;
        background-color: #000;
        color: #fff;
        width: 100%;
        border-radius: 100px;
        text-align: center;

        &::placeholder{
            color: #dddddd;
            font-weight: 3\500;
            font-size: 14px;
        }

        &:focus{
            border-color: green;
        }
        
    }

    .closeInput{

        height: 40px;
        width: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 12px;
        cursor: pointer;

    }

    .toast_message{
        
        background-color: #131313;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 80px;
        color: #fff;
        font-weight: 500;
        left: 0;
        z-index: -2;

        .live{
            background-color: rgb(197, 220, 197);
            color: green;
            font-weight: 600;
            padding: 3px;
            border-radius: 5px;
        }

        .info{
            align-items: center;
            gap: 10px;
        }

        .micIcon {

            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            background-color: green;
            color: #fff;
            font-size: 33px;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color 0.25s;
          }
          
          .micIcon::before {
            content: "";
            position: absolute;
            width: inherit;
            height: inherit;
            border-radius: inherit;
            background-color: inherit;
            z-index: -1;
          }
          
          .listening {
            background-color: green;
          }
          .listening::before {
            animation: listening 1.3s infinite;
          }
          
          @keyframes listening {
            from {
              opacity: 0.25;
            }
            to {
              transform: scale(2);
              opacity: 0;
            }
        }
    }

}

.results__calculations{

    width: 100%;
    text-align: center;
    margin: 40px 0;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #333;

}

.search__query__results{

    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 200px;
    width: 100%;

    p{
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        width: 100%;
    }

    .relations{

        margin-top: 20px;
        width: 100%;

        .relations__title{
            font-size: 11px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 30px;
        }

    }

    .result__card{

            background-color: #f6f6f6;
            background-color: #fff;
            border: 1px solid #eee;
            color: var(--dark);
            width: calc(33% - 25px);
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 25px 30px;
            border-radius: 10px;
            cursor: pointer;
        
        .title{
            font-size: 17px;
            line-height: 27px;
            font-weight: 600;
            margin-bottom: 0px;
            text-decoration: underline;
        }


        .subtitle{

            font-size: 14px;
            margin-bottom: 0px;
            text-transform:capitalize;
            line-height: 23px;

        }

        .themeArea{
            font-size: 11px;
            letter-spacing: 2px;
            font-weight: 600;
            color: #108a00;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            bottom: 0px;
            margin-top: auto;
        }

        &:hover{
            color: #fff;
            background-color: #108a00;

            .subtitle{
                color: #fff;
            }

            .themeArea{
                color: #fff;
            }
        }

    }

}

.load{
    background-color: transparent;
    width: 100%;
}

@media (max-width : 1300px) {

    .search__title{
        .top{
            width: 80%;
        }
    }

}


@media (max-width : 1200px) {

    .search__component{

        padding-top: 30px;
        overflow-y: auto;
    
        .close__search__modal{
    
            right: 0px;
        }
    }

    .search__query__results{
    
        .result__card{
    
            width: calc(50% - 25px);
            background-color: #fff;
            box-shadow: 2px 5px 20px rgba($color: #000000, $alpha: .04);
            padding: 20px;
            border-radius: 7px;
            cursor: pointer;
            position: relative;
            // height: max-content;
            
            .title{
                font-size: 21px;
                line-height: 27px;
                font-weight: 600;
                margin-bottom: 0px;
                text-decoration: underline;
            }
    
    
            .subtitle{
    
                font-size: 15px;
                margin-bottom: 0px;
                // display: none;
    
            }
    
        }
    
    }
    
}


@media (max-width : 1000px) {

    .search__component{

        padding-top: 30px;
        overflow-y: auto;
    
        .close__search__modal{
    
            right: 0px;
        }
    }

    .search__title{

        .top{
            width: 100%;
        }
    
    }

    .results__calculations{

        margin: 40px 0;
        font-size: 12px;
    
    }

    .search__query__results{

        padding-bottom: 200px;
        height: 100%;
        justify-content: center;
    
    }
    
}

@media (max-width : 820px) {

    
}

@media (max-width : 768px) {

    .search__title{

        .top{
            font-size: 40px;
            line-height: 47px;
        }
    }

    .search__query__results{
    
        .result__card{
    
            &:hover{
                color: #000;
                background-color: #fff;
    
                .subtitle{
                    color: #000;
                }
    
                .themeArea{
                    color: #108a00;
                }
            }

            .title{
                font-size: 18px;
                line-height: 26px;
            }
    
        }
    
    }
    
}

@media (max-width : 550px) {

    .search__component{
        .close__search__modal{
            font-size: 28px;
        }
    }

    .search__title{

        width: 100%;
        margin-bottom: 30px;

        .top{
            font-size: 35px;
            line-height: 42px;
        }
    }

    .search__query__results{

        gap: 10px;
    
        .result__card{
    
            width: calc(50% - 10px);
            gap: 15px;

            .title{
                font-size: 18px;
                line-height: 26px;
            }

            .subtitle{
                font-size: 14px;
                line-height: 21px;
            }
    
        }
    
    }
    
}


@media (max-width : 500px) {

    .search__query__results{

        gap: 10px;
    
        .result__card{
    
            width: 100%;
            gap: 15px;

            .title{
                font-size: 16px;
                line-height: 26px;
            }
    
        }
    
    }
    
}



@media (max-width : 450px) {

    .feedback{
        font-size: 16px !important;
        line-height: 24px;
    }

    .search__component{
        .close__search__modal{
            font-size: 25px;
            right: 10px;
            top: 20px;
        }
    }

    .search__title{


    align-items: flex-start;
    gap: 10px;
    padding-bottom: 30px;
    margin-bottom: 40px;

        .top{
            font-size : 30px;
            line-height: 37px;
            letter-spacing: 0;
            text-align: left;
        }

        .sub{
            font-size: 10px;
        }

    }

    .search__field{
        input{
            text-align: left;
        }
    }
    
}

@media (max-width : 400px) {

    .search__component{
        .close__search__modal{
            font-size: 25px;
        }
    }

    .search__title{

        .sub{
            font-size: 10px;
        }

    }

    .search__query__results{
    
        .result__card{
    
            width: 100%;

            .title{
                font-size: 16px;
                line-height: 26px;
            }
    
        }
    
    }
    .results__calculations{
        margin: 30px 0;
        font-size: 11px;
        margin-bottom: 20px;
    }
    
}