.subscribe{

    margin-top: 0px;
    position: relative;
    padding: 1px 0;

    .subscribe__card{

        background-color: #009C3E;
        padding: 120px 80px;
        line-height: 25px;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 70px;

        .subscribe__action__button{

            padding: 15px 25px;
            color: #42FF01;
            background-color: rgb(0, 22, 0);
            position: absolute;
            z-index: 4;
            border-radius: 8px;
            right: 80px;
            display: flex;
            align-items: center;
            gap: 7px;
            font-weight: 500;
            cursor: pointer;

        }

        .path{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                mix-blend-mode: multiply;
                opacity: .4;
            }
        }

        .main{

            font-size: 40px;
            color: #fff;
            font-weight: 600;
            width: 60%;
            line-height: 45px;
            position: relative;

        }

        .main__sub{

            color: #fff;
            width: 40%;
            line-height: 22px;
            margin-top: 30px;
            position: relative;

        }

    }

}

@media (max-width : 1000px) {

    .subscribe{

        .subscribe__card{

            .subscribe__action__button{
                
                bottom: 50px;
                right: 50px;

            }

            .main{
                width: 100%;
            }

            .main__sub{
                width: 60%;
            }
        }

    }
    
}

@media (max-width : 768px) {

    .subscribe{

        .subscribe__card{

            padding: 50px;

            .subscribe__action__button{
                
                bottom: 50px;
                right: 50px;

            }

            .main{
                width: 100%;
                font-size: 35px;
                line-height: 40px;
            }

            .main__sub{
                width: 50%;
            }
        }

    }
    
}

@media (max-width : 700px) {

    .subscribe{

        .subscribe__card{

            padding: 50px;

            .subscribe__action__button{
                
                position: relative !important;
                width: max-content;
                bottom: 0;
                left : 0;
                margin-top: 50px;


            }

            .main{
                width: 100%;
                font-size: 35px;
                line-height: 40px;
            }

            .main__sub{
                width: 80%;
                display: none;
            }
        }

    }
    
}

@media (max-width : 600px) {

    .subscribe{

        .subscribe__card{

            padding: 40px;

            .subscribe__action__button{
                
                position: relative !important;
                width: max-content;
                bottom: 0;
                left : 0;
                margin-top: 50px;


            }

            .path{
                img{
                    width: 150%;
                    height: 150%;

                }
            }

            .main{
                width: 100%;
                font-size: 35px;
                line-height: 40px;
            }

            .main__sub{
                width: 80%;
                display: none;
            }
        }

    }
    
}

@media (max-width : 500px) {

    .subscribe{

        .subscribe__card{

            padding: 40px;

            .subscribe__action__button{
                
                position: relative !important;
                width: max-content;
                bottom: 0;
                left : 0;
                margin-top: 50px;


            }

            .path{
                img{
                    width: 150%;
                    height: 150%;

                }
            }

            .main{
                width: 100%;
                font-size: 30px;
                line-height: 35px;
            }

            .main__sub{
                width: 80%;
                display: none;
            }
        }

    }
    
}

@media (max-width : 400px) {

    .subscribe{

        .subscribe__card{

            padding: 40px;

            .subscribe__action__button{
                
                position: relative !important;
                width: max-content;
                bottom: 0;
                left : 0;
                margin-top: 50px;


            }

            .path{
                img{
                    width: 150%;
                    height: 150%;

                }
            }

            .main{
                width: 100%;
                font-size: 27px;
                line-height: 32px;
            }

            .main__sub{
                width: 80%;
                display: none;
            }
        }

    }
    
}