.quickCheck{
    overflow: hidden;
    position: relative;
    background-color: #fff;
    padding: 100px 0px;
    padding-bottom: 70px;

    .lineBg{
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
        right: -100px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotateY(180deg);
        display: none;

        img{
            height: 150%;
        }
    }

    .getStarted{
        
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        padding: 0px 0;
        position: relative;
        z-index: 3;
        align-items: center;

        .title{
            margin-top: 30px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-bottom: 0px;
        }

        .real{
            margin-bottom: 10px;
            font-size: 17px;
            font-weight: 500;
        }
    
        .textString{
    
            font-weight: 500;
            position: relative;
            color: #000;
            border-radius: 10px;
            width: 60%;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;

            .textChange{
    
                width: 100%;
                font-size: 50px;
                font-weight: 600;
                line-height: 60px;
                letter-spacing: -1px;
                margin-bottom: 20px;

                .many{
                    line-height: 35px;
                    padding-bottom: 15px;
                }    
                
            }
            
            .pasty{

                margin-top: 15px;
                width: 70%;
                font-weight: 400;
                line-height: 30px;
                color: #444;
                text-align: center;
                font-size: 18px;
            
            }

        }  
        
        .linkServices{

            font-size: 16px;
            color: #808080;

            a{
                color: #009C3E;
                text-decoration: underline;
                cursor: pointer;
                font-weight: 500;
            }
        }
    
        .quickAsapLinks{

            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            gap: 30px;
            overflow: hidden;
            overflow-x: auto;
            scrollbar-width: none;
            padding-bottom: 30px;
            margin-top: 40px;
            scroll-behavior: smooth;
            z-index: 2;

            &::-webkit-scrollbar{
                display: none !important;
            }
            
            .content{

                transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.8);
            }

    
            .linkBin{
                width: 400px;
                min-width: 400px;
                // border: 1px solid rgba($color: #000000, $alpha: 0.06);
                cursor: pointer;
                display: flex;
                position: relative;
                padding: 0;
                border-radius: 10px;
                background-color: #fff;
                overflow: hidden;
                box-shadow: 1px 3px 30px rgba($color: #000000, $alpha: .04);

                &:last-child{
                    border: none;
                }
    
                &.untold{
    
                    gap: 0px;
    
                    p{
                        margin-left: auto;
                        margin-top: -1px;
                        font-weight: 600;
                        color: var(--lime);
                    }
    
                }

                .content{

                    .img_quick{

                        height: 220px;
                        overflow: hidden;

                        img{

                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: all 1s ease-in-out;

                        }

                    }

                    .quick__title{

                        font-weight: 500;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        line-height: 21px;
                        color: #131313;
                        font-size: 18px;
                        padding: 30px;

                        .main__text{
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            span{
                                margin-top: 4px;
                            }
                        }


                        p{
                            font-weight: 400;
                            font-size: 11px;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            margin-top: 15px;
                            color: #555;

                        }
        
                        .icon{
                            position: absolute;
                            top: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            color: #009C3E;
                            right: 20px;
                            transform: translateY(-50%);
                        }
                    }

                    .button__quick{

                        margin: 0 20px;
                        background-color: #009C3E;
                        font-size: 11px;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        padding: 12px 18px;
                        width: max-content;
                        border-radius: 3px;
                        margin-bottom: 20px;
                        margin-top: 10px;
                        display: none;

                    }
                }
    
                &:hover{
    
                    border-color: #009C3E;
                    background-color: #009C3E;
                    color: #000;
                    border-radius: 5px;

                    .img_quick{
                        img{
                            transform: scale(1.1);
                        }
                    }

                    .icon{
                        color: #fff;
                        right: 30px;
                        top: 35px;
                        transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.8);
                    }

                    .quick__title{
                        text-decoration: underline;
                    }

                    .quick__title, p{
                        color: #fff !important;
                    }
    
                }

            }
    
        }
    }

}

@media (max-width : 1200px) {

    .quickCheck{

        .getStarted{

            .textString{
                width: 80%;
            }

        }

    }
    
}

@media (max-width : 768px) {

    .quickCheck{

        .controlArea{
            display: none;
        }

        .getStarted{

            .textString{
                width: 100%;

                .textChange{
                    font-size: 45px;
                    line-height: 50px;
                }
            }

            .quickAsapLinks{

                flex-wrap: wrap;
                gap: 20px;

                .linkBin{

                    width: calc(50% - 15px);
                    min-width: calc(50% - 15px);

                }

            }
            
        }

    }
    
}

@media (max-width : 550px) {

    .quickCheck{

        padding: 50px 5px;
        padding-bottom: 30px;

        .lineBg{
            display: flex;
            opacity: .07;
        }

        .getStarted{

            .textString{
                width: 100%;

                .textChange{
                    font-size: 35px;
                    line-height: 20px !important;
                    letter-spacing: -0.5px;
                    text-align: left;

                    .many{
                        line-height: 26px;
                    }
                }

                .pasty{
                    width: 95%;
                    line-height: 24px;
                    padding-left: 0;
                    margin-top: 0;
                    font-size: 16px;
                    text-align: left;
                }
            }

            .quickAsapLinks{

                flex-wrap: wrap;
                gap: 20px;
                margin-top: 10px;
                padding-bottom: 0;

                .linkBin{

                    width: 100%;
                    min-width: 100%;

                    &:last-child{
                        margin: 0;
                    }

                    .content .quick__title{
                        padding: 20px;
                    }

                    .content{
                        .img_quick{
                            height: 180px;
                        }
                    }

                }

            }
            
        }

    }
    
}




