.mic{
    width: 50px;
    height: 50px;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}


@media (max-width : 850px) {

    .mic__holder{
        display: none;
    }

}