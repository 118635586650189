.view_service_modal{
    background-color: rgba($color: #000000, $alpha: .5);
    height: 100dvh;
    width: 100%;
    position: fixed;
    z-index: 99999;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: 0;

    .modal_item{

        background-color:#fff;
        width: 700px;
        padding: 50px;
        position: relative;
        margin: 80px 0;
        overflow-y: auto;
        height: max-content;
        max-height: calc(100vh - 150px);
        border-radius: 10px;

        .closeModal{
            position: absolute;
            top: 0;
            right: 0;
            margin: 30px;
            cursor: pointer;
        }

        .modal_info{

            line-height: 24px;
            text-align: justify;

            p, li, ol, ul, a{
                font-size: 14px !important;
            }

            ol, ul{
                margin-left: 14px;
                margin-top: 5px;
            }

            a{
                color: var(--primary25);
                text-decoration: underline;
            }

            iframe{
                width: 100%;
                height: 300px;
            }

            img{
                width: 100%;
                height: 300px;
                object-fit: cover;
            }

        }

        .modal_theme{

            background-color: #deeee0;
            width: max-content;
            font-size: 10px;
            margin-bottom: 20px;
            padding: 3px;
            color: var(--primary25);
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 3px;

        }

        .modal_title{

            font-size: 28px;
            width : 80%;
            text-transform: capitalize;
            font-weight: 500;
            margin-bottom: 40px;

        }

        .modal_CTA{

            padding: 15px 22px;
            margin-top: 50px;
            background-color: var(--primary25);
            width: max-content;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            background-color: #1C3F3A;
            border-radius: 5px;

        }

    }
}

@media (max-width : 750px) {

    .view_service_modal{

        .modal_item{

            width: 90%;

        }

    }
}

@media (max-width : 500px) {

    .view_service_modal{

        .modal_item{

            width: 100%;
            border-radius: 20px 20px 0 0;
            position: absolute;
            bottom: 0;
            padding: 30px;
            margin: 0;

            .modal_title{
                width: 100%;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                margin-top: 10px;
                margin-bottom: 30px;
            }

        }

    }
}