.subscribeModal{

    background-color: rgba($color: #000000, $alpha: .5);
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
    height: 100vh;
    color: #000;
    padding: 100px 0;

    .buttons__galor{
        position: absolute;
        bottom: -110px;
        right: 0;
        display: flex;
        gap: 15px;

        .submit__btn{

            padding: 15px 25px;
            background-color: #009C3E;
            font-size: 14px;
            color: #fff;
            font-weight: 600;
            text-align: center;
            border-radius: 3px;
            cursor: pointer;
        }

        .cancel{
            background-color: #e3e3e3;
            color: #000;
        }
    }


    .modal{

        display: flex;
        gap: 20px;
        position: relative;

        .persona{

            width: 40%;

            .lasg__topic{

                font-size: 12px;
                letter-spacing: 2px;
                margin-bottom: 30px;
                color: #777;

            }

            .greeting{
                
                font-size: 40px;
                width : 85%;
                font-weight: 550;
                line-height: 40px;
                letter-spacing: -1px;
                margin-bottom: 50px;

                span{
                    color: #009C3E;
                }

                .many{
                    
                    &:nth-child(8){
                        color: #009C3E;
                    }

                    &:nth-child(9){
                        color: #009C3E;
                    }

                    &:nth-child(10){
                        color: #009C3E;
                    }

                }

                .many{
                    padding-bottom: 5px;
                }
            }

            .personalInfo{

                p{
                    margin-bottom: 50px;
                }

                .inputForm{

                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    gap: 8px;
                    margin-bottom: 20px;

                    input{
                        outline: none;
                        border: none;
                        background-color: #f9f9f9;
                        padding: 15px;
                        border-radius: 5px;
                    }

                    label{
                        font-size: 13px;
                        font-weight: 600;
                    }
                }
            }

        }

        .interests__body{

            width: 60%;
            padding: 30px;
            background-color: #f9f9f9;
            height: 680px;
            overflow: hidden;
            overflow: auto;
            position: relative;

            p{
                margin-bottom: 40px;
                width: 60%;
                line-height: 23px;
                font-size: 18px;
                font-weight: 600;
            }

            .interests{
                display: flex;
                flex-direction: column;
                gap: 10px;

                .interest{
                    border-bottom: 1px solid #00000018;
                    padding-bottom: 20px;

                    &:last-child{
                        border: none;
                    }
                }

            }
        }
    }

}


/* The interest */
.interest {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .title{

        font-size: 14px;
        
    }
  }
  
  /* Hide the browser's default checkbox */
  .interest input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 3px;
  }
  
  /* On mouse-over, add a grey background color */
  .interest:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a green background */
  .interest input:checked ~ .checkmark {
    background-color: #009C3E;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .interest input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .interest .checkmark:after {
    left: 6.8px;
    top: 2.5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


