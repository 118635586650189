.viewService{
    
    background-color: #fdfdfd;

    .viewArea{

        padding-top: 150px;
        
        .navigationPin{

            display: flex;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0px;
            cursor: pointer;
            gap: 20px;
            position: relative;
            margin-bottom: 30px;
            justify-content: center;

            .goBack{

                display: flex;
                gap: 20px;
                align-items: center;
                font-size: 21px;
                position: absolute;
                left: 0;

            }

            .shareBtn{
                font-size: 16px;
                font-weight: 500;
            }

            .serviceTitle{

                font-size: 24px;
                line-height: 57px;
                letter-spacing: -0.5px;
                font-weight: 700;
                text-align: center;

            }

        }

        .serviceName{

            display: flex;
            gap: 25px;
            margin-bottom: 0px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: red;

            .shorts{
                margin-bottom: 50px;
                width: 50%;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
    

            .theme_icon{

                width: 40px;
                height: 40px;

                img{
                    width: 100%;
                    height: 100%;
                }

            }

        }

        .searchField{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
    
            .searchInput{
    
                height: 60px;
                outline: none;
                border: none;
                background-color: #000;
                color: #fff;
                width: 50%;
                position: relative;
                z-index: 5;
                padding: 0 20px;
                border: 4px solid transparent;
                border-radius: 100px;
                text-align: center;

                &::placeholder{
                    font-size: 15px;
                    color: #a8a8a8;
                }
            }
    
            .typeSide{
    
                position: absolute;
                width: 40%;
                background: #000;
                z-index: 3;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 30px;
                font-size: 14px;
                color: #fff;
                border-radius: 100px;
    
                .Typewriter__cursor{
                    display: none;
                }
    
            }
    
            input:focus ~ .typeSide{
                display: none;
            }

            input:focus{
                background-color: #000;
                border-color: green;
            }
    
        }

        .mdas_sections{

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 50px;
            margin-top: 50px;
    
            .section{
    
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 2px;
                cursor: pointer;
    
                &:hover{
    
                    color: var(--primary25);
    
                }
    
            }
    
        }
    
    
        .resultArea{
    
            margin-top:50px;
            display: flex;
            flex-wrap: wrap;
            gap: 22px;
            justify-content: center;
            margin-bottom: 50px;
    
            .parentHere{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                
            }
    
            .mda_card{
    
                background-color: #f6f6f6;
            background-color: #fff;
            border: 1px solid #e9e9e9;
            color: var(--dark);
            width: calc(33% - 25px);
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 25px 30px;
            border-radius: 10px;
            cursor: pointer;

            .details__zone{
                align-items: center;
                gap: 20px;
                height: 50px;
            }
    
            .logo{
                width: 30px;
                height: 30px;
                overflow: hidden;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
                
                .mda_name{
    
                    font-weight: 600;
                font-size: 16px;
                line-height: 24px;
    
                }
    
                .mda_desc{
                    font-size: 14px;
                    line-height: 22px;
                    margin-top: 10px;
                    color: #777;

    
                }
    
                .arrowMai{
    
                    margin-left: auto;
                    margin-right: 10px;
                    color: var(--dark);
                    height: 50px;
                    width: 50px;
                    position: relative;
                    overflow: hidden;
                    display: none;
    
                    .arrowMe{
    
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        translate: -50% -50%;
                        transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    
                        &.bot{
    
                            left: calc(-50% - 10px);
                            top: calc(100% + 10px);
                            translate: 50% -50%;
    
                        }
                        
                    }
    
                }
    
                &:hover{
                    
                    border-color: green;
                }
    
    
            }
    
        }

    }

}


@media (max-width : 1200px) {

    .viewService{

        .viewArea{

            padding-top: 150px;

            .resultArea{

                .mda_card{
                    .details__zone{
                        flex-direction: column;
                        height: 90px;
                        align-items: flex-start;
                    }
                }
            }

        }

    }

}

@media (max-width : 1000px) {

    .viewService{

        .viewArea{


        padding-top: 130px;
        

            .navigationPin{
                margin-bottom: 20px;
            }

            .serviceName{
    
                .serviceTitle{
    
                    font-size: 40px;
    
                }
    
            }

            .searchField{
        
                .searchInput{

                    width: 80%;
                }
        
                .typeSide{
        
                    width: 80%;
        
                }
            }

            .mdas_sections{

                margin-top: 40px;
        
            }

            .resultArea{

                margin-top: 40px;

                .mda_card{
                    width: calc(50% - 25px);
                }
            }

        }

    }

}

@media (max-width : 768px) {

    .viewService{

        .viewArea{

            .navigationPin{

                display: flex;
                font-size: 12px;
                font-weight: 500;
                cursor: pointer;
                justify-content: flex-start;
    
                .goBack{
    
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    position: relative;
    
                }
    
            }

            .serviceName{
    
                .serviceTitle{
    
                    font-size: 30px;
                    width: 100%;
                    text-align: center;
                    font-size: 21px;
    
                }

                .shorts{

                    width: 100%;
                    font-size: 12px;
                    line-height: 22px;
                    margin-bottom: 40px;
                }
    
            }

            .searchField{
        
                .searchInput{

                    width: 100%;
                }
        
                .typeSide{
        
                    width:  100%;
        
                }
            }

            

            

        }

    }

}

@media (max-width : 768px) {

    .viewService{

        .viewArea{

            .mdas_sections{

                margin-top: 30px !important;
        
            }

            .resultArea{
                gap: 10px;

                .mda_card{

                    width: calc(50% - 10px);

                }
            }

            

        }

    }

}

@media (max-width : 500px) {

    .viewService{

        .viewArea{

            padding-top: 90px;

        }

    }

}



@media (max-width : 550px) {

    .viewService{

        .viewArea{

            .resultArea{
                gap: 10px;

                .mda_card{

                    width: calc(100% - 10px);
                    gap: 5px;

                    .details__zone{
                        height: max-content;
                    }

                }
            }

            

        }

    }

}


