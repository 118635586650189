.controls{

    position: absolute;
    right: 0;
    z-index: 4;
    gap: 10px;
    width: 93%;
    justify-content: space-between;
    bottom: 320px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;

    .arrow__nav{

        height: 50px;
        width: 50px;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        font-size: 18px;
        font-size: 50px;

        .abs{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: 3px;
            transition: all .2s ease-in-out;
            color: #1c3f3a;
        }

        &.abs1:hover{

            .abs{
                margin-left: -2px;
            }

        }

        &.abs2:hover{

            .abs{
                margin-left: 2px;
            }

        }
    }
}