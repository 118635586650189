.guidePack{

    width: 85%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;

}

// @media (max-width : 1440px) {

//     .guidePack{

//         width: calc(100% - 60px);
    
//     }

// }

@media (max-width : 550px) {

    .guidePack{

        width: calc(100% - 20px);
    
    }

}