.budgetting{

    padding: 60px 0;
    margin-top: 20px;
    justify-content: center;
    gap: 50px;
    align-items: center;

    .divider{
        height: 60px;
        width: 1px;
        background-color: #e1e1e1;
    }
    

    .budget__card{

        display: flex;
        flex-direction: column;
        gap: 15px;

        .tiny{
            font-size: 11.4px;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 500;
            color: #444;
        }

        p{
            font-size: 40px;
            font-weight: 700;
            letter-spacing: -1px;
            color: #1c3f3a;
        }
    }

}

@media (max-width : 1500px){
    .budgetting{
        justify-content: space-between;
        gap: 0;
    }
}

@media (max-width : 1350px){
    .budgetting{

        .budget__card{
           p{
            font-size: 35px;
           }
        }
    }
}

@media (max-width : 1300px){
    .budgetting{

        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 50px;
        row-gap: 30px;

        .budget__card{
           p{
            font-size: 35px;
           }
        }
    }
}

@media (max-width : 1200px){
    .budgetting{

        padding: 40px 0;
    }
}

@media (max-width : 500px){
    .budgetting{

        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
        row-gap: 40px;

        .divider{
            display: none;
        }

        .budget__card{
           p{
            font-size: 35px;
           }
        }
    }
}

@media (max-width : 500px){
    .budgetting{

        width: 100%;
        flex-wrap: nowrap;
        justify-content: center;
        flex-direction: column;
        gap: 50px;
        row-gap: 30px;

        .divider{
            display: block;
            height: 1px;
            width: 100%;
        }

        .budget__card{

            align-items: center;

           p{
            font-size: 40px;
           }

        }
    }
}